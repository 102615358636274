









































































import { Component, Prop, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"

/** Components */
import ModalBase from "@/components/ModalBase.vue"

/** Types | Api */
import BreakpointMixin from "@/types/BreakpointMixin"
import ProductGroupDimension from "@/types/Product/ProductGroupDimension"
import ProductCategoryDimension from "@/types/Product/ProductCategoryDimension"
import ProductApi from "@/api/Product/ProductApi"

/** Utils */
import { Cookies } from "@/utils/cookies"

@Component({
  name: "CardProductAction",
  components: { ModalBase }
})
export default class ProductCategoryFilterDrawer extends mixins(BreakpointMixin) {
  private isActive: boolean = false
  private isActiveSnack: boolean = false
  private message: string = ""
  private productGroupDim: ProductGroupDimension[] = []
  private productCateDim: any[] = []

  private async compare() {
    if (this.getCompare.dimensions === 0) {
      this.$store.commit("setMsgSnack", this.messageAtlease)
      this.$store.commit("toggleSnack", true)
      return
    }

    await ProductApi.getProductGroupDimension(this.$store.state.groupId).then(({ data }) => {
      this.productGroupDim = _.map(data, (d) => deserialize<ProductGroupDimension>(d, ProductGroupDimension))
    })

    let _productCateDim: any[] = []
    this.productCateDim = []

    for (let i = 0; i < this.getCompare.dimensions.length; i++) {
      await ProductApi.getProductCategoryDimension(this.getCompare.dimensions[i].productCategoryId)
        .then(({ data }) => data)
        .then((res) => {
          if (!res.length) {
            let dimensions: ProductCategoryDimension[] = []
            for (let index = 0; index < this.productGroupDim.length; index++) {
              dimensions.push({ value: "", sort: 0, groupId: -1 })
            }
            _productCateDim.push(dimensions)
          } else {
            let productCateDim =
              _.map(res, (d) => deserialize<ProductCategoryDimension>(d, ProductCategoryDimension)) || []

            let dim: ProductCategoryDimension[] = []

            this.productGroupDim.forEach((group) => {
              const fingMatchGroup = productCateDim.find((cate) => cate.groupId === group.id)
              if (fingMatchGroup) {
                dim.push({
                  groupId: fingMatchGroup.groupId,
                  value: fingMatchGroup.value,
                  sort: fingMatchGroup.sort
                })
              } else {
                dim.push({
                  groupId: -1,
                  value: "",
                  sort: -1
                })
              }
            })

            _productCateDim.push(dim)
          }
        })
    }

    this.productCateDim = _productCateDim
    this.isActive = true
  }

  private async clearCompare() {
    this.isActive = false
    await new Promise((r) => setTimeout(r, 100))

    this.$store.commit("clearCompare")
  }

  private deleteItem(filePath: string, index: number) {
    this.productCateDim.splice(index, 1)
    if (this.productCateDim.length === 0) {
      this.isActive = false
    }

    const itemsRemoved = this.getCompare.dimensions.filter((dimension: any) => dimension.filePath !== filePath)
    this.$store.commit("setDimension", itemsRemoved)
    if (!itemsRemoved.length) {
      this.$store.commit("clearCompare")
    }
  }

  private close() {
    this.isActiveSnack = false
  }

  private get imageHeight() {
    const width = window.screen.width
    return width > 1440 ? "210px" : width > 1280 ? "99px" : width < 991 ? "155.04px" : "424px"
  }

  private get imageWidth() {
    const width = window.screen.width
    return width > 1440 ? "210px" : width > 1280 ? "99px" : width < 991 ? "99px" : "99px"
  }

  private get btnHeight() {
    const width = window.screen.width
    return width > 1440 ? "100px" : width > 1280 ? "86px" : width < 991 ? "26px" : 850
  }

  private get modalWidth() {
    const width = window.screen.width
    return width > 1440 ? 1372 : width > 1280 ? 1000 : width < 991 ? 310 : 1000
  }

  private get messageAtlease() {
    return `${this.$t("product.detail.messageAtlease")}`
  }

  private get getCompare() {
    const dimensions = localStorage.getItem("dimensions") || null
    const _dimensions = this.$store.state.dimensions.length
      ? this.$store.state.dimensions
      : dimensions
      ? JSON.parse(dimensions)
      : []

    return {
      groupId: this.$store.state.groupId || localStorage.getItem("groupId") || null,
      dimensions: _dimensions,
      isActive: _dimensions.length > 0 && Cookies.getCookieByKey(Cookies.acceptFuctional)
    }
  }
}
